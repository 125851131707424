import * as React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import {BrowserRouter} from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import theme from './theme';
import Index from "./pages";
import Dashboard from './pages/dashboard';
import CalendarPage from './pages/calendar';
import UserPage from "./pages/user";
import {FRONT_END_LOGOUT_DEV_MODE} from "./config/CONSTANTS";
import FindAccountTypeIntentModal from "./components/FindAccountTypeIntentModal";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import AppointmentPage from "./pages/appointments";
import ServicePage from "./pages/services";
import LandingPage from "./LandingPage";


const NApp = () => {
    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
    const target = getCookie('redirect');

    const removeHash = () => {
        try {
            history.pushState("", document.title, window.location.pathname
                + window.location.search);
        }catch (e) {}
    }

    let mightBeAuthenticated = (window.localStorage.getItem('token'));

    let hash = window.location.hash;
    if(hash && hash.startsWith('#token=')){
        let fullUrl = window.location.href;
        let params = (new URL(fullUrl.replace('#token=','?token='))).searchParams;
        const paramToken = params.get('token');

        if(paramToken){
            mightBeAuthenticated = true;
            window.localStorage.setItem('token',paramToken)
            removeHash()
            if(target === 'card'){
                window.location.href='/card';
            }
        }
    } else if(FRONT_END_LOGOUT_DEV_MODE){
        let fullUrl = window.location.href;
        let params = (new URL(fullUrl)).searchParams;
        const paramToken = params.get('logout');

        if(paramToken){
            mightBeAuthenticated = true;
            window.localStorage.removeItem('token')
            removeHash()
        }
    }

    if(mightBeAuthenticated || window.location.pathname === "/"){
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <BrowserRouter>
                    <Routes>
                        <Route path="/v2" element={<Index />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route exact path="/user" element={ <UserPage />} />
                        <Route exact path="/calendar" element={ <CalendarPage />} />
                        <Route exact path="/appointments" element={ <AppointmentPage />} />
                        <Route exact path="/services" element={ <ServicePage />} />
                        <Route exact path="/" element={ <LandingPage />} />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        )
    } else {
        // window.location.href = AUTH_URL
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <BrowserRouter>
                    <Routes>
                        <Route  path="/*" element={ <LandingPage />} />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        )
    }
}

ReactDOM.render(<NApp/>,
  document.querySelector('#root'),
);



const goodDate = (v) =>{
    let now = new Date();
    let d = new Date(v);
    now.setDate(now.getDate() + 5);
    const n_days_ahead = d > now;
    const good_time_range = d.getHours() >= 9 && d.getHours() < 18;
    return n_days_ahead && good_time_range
}

module.exports={
    goodDate,

}

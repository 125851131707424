import React, {useState, useCallback, useRef, Fragment, useEffect} from "react";
import {
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import FormDialog from "./FormDialog";
import ButtonCircularProgress from "./ButtonCircularProgress";
import VisibilityPasswordTextField from "./VisibilityPasswordTextField";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {APP_NAME} from "../../config/CONSTANTS";
import OauthList from "./OauthList";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration:'none',
    transition: theme.transitions.create(["background-color"], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
    }),
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:enabled:hover": {
      color: theme.palette.primary.dark,
    },
    "&:enabled:focus": {
      color: theme.palette.primary.dark,
    },
  },
  haveAnAccount: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main,
    cursor: "pointer",
    border: 0,
    outline:"none"
  },
  formControlLabel: {
    marginLeft:5,
    // paddingBottom:14,
    cursor:'pointer',
    fontSize:'0.9em',
    color: "gray",
    "&:enabled:hover": {
      color: "gray",
    },
    "&:enabled:focus": {
      color: "gray",
    },
  },
}));


function RegisterDialog(props) {
  const classes = useStyles()
  const {onClose} = props;
  const [isLoading, setIsLoading] = useState(false);
  const [promptSocial, setPromptSocial] = useState(true);
  const [forgot,setForgot] = useState(false);
  const [beforeCode, setBeforeCode] = useState(true);
  const [logIn, setLogIn] = useState(props.startWithLogin);
  const [permitted,setPermitted]=useState(false);
  const [passwordResetSuccess,setPasswordResetSuccess]=useState(false);

  return (
    <FormDialog
        displayBack={!promptSocial}
        clickBack={()=>{
          setPromptSocial(true);
          setForgot(false);
          setPermitted(false);
          setPermitted(false);
          setPasswordResetSuccess(false);
        }}
        disclaimer
        disableBackdropClick={true}
      loading={isLoading}
      onClose={onClose}
      open
      headline={APP_NAME}
      onFormSubmit={(e) => {
        e.preventDefault();
      }}
      hideBackdrop
      hasCloseIcon
      content={
        <Fragment>
          {promptSocial &&
          <OauthList onClickNotSocial={()=>{
            setPromptSocial(false);
            setForgot(false);
            setPermitted(false);
            setPermitted(false);
            setPasswordResetSuccess(false);
          }}/>
          }

        </Fragment>
      }
      actions={
        <Fragment>
          {beforeCode && !promptSocial && !logIn &&
            <Typography style={{
            marginTop:0,
            paddingTop:0,
            marginBottom:10,
          }} variant="body2">
            By continuing, you agree to the
            <a
                target="_blank" rel="noopener noreferrer"
                href={'/terms'}
                className={classes.link}
                tabIndex={0}
                role="button"
            >
              {" "}
              Terms of Use
            </a>
          </Typography>
            }

        </Fragment>
      }
    />
  );
}

export default RegisterDialog;

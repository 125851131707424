import AppBar from "@material-ui/core/AppBar";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import * as CONSTANTS from "../config/CONSTANTS";
import Drawer from "@material-ui/core/Drawer";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import {indexListItems, mainListItems, SecondaryListItems} from "./listItems";
import React, {useCallback, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import DialogSelector from "./RegisterLogin/DialogSelector";
import {Button} from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { useNavigate } from "react-router-dom";
import {AUTH_URL} from "../config/CONSTANTS";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({

    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),


    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),

    },
    breakContainer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(3),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },

    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(14, 0, 6)
    },
    stepTitle: {
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        // paddingTop: theme.spacing(8),
        // paddingBottom: theme.spacing(8),
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(3),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor:'pointer'
    },
    cardLink:{
        color: "inherit",
        textDecoration:"none"

    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardTitle:{
        // height: '100%',
        // width:'100vw',
        paddingTop: '56.25%', // 16:9
        marginTop: '0', // 16:9

        paddingLeft:'0',
        marginLeft:'0'

    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    customHeader:{

        [theme.breakpoints.down('sm')]: {
            fontSize:"2.5rem",
        },
        [theme.breakpoints.up('md')]: {
            fontSize:"4.0rem",
        },
        [theme.breakpoints.up('lg')]: {
            fontSize:"5.0rem",
        },
        // fontSize:"5.0rem",
        fontWeight:"700",
        marginBottom:10
    },
    customHeaderBelow:{
        [theme.breakpoints.down('sm')]: {
            fontSize:"1.25rem",
        },
        [theme.breakpoints.up('md')]: {
            fontSize:"1.5rem",
        },
        [theme.breakpoints.up('lg')]: {
            fontSize:"2.0rem",
        },
        // fontSize:"5.0rem",
        fontWeight:"400"
    },
    drawerPaper: {
        position: 'fixed',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        visibility:"visible",
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
        [theme.breakpoints.down('sm')]: {
            visibility:"hidden",
        },
        [theme.breakpoints.up('md')]: {
            visibility:"visible",
        },
        [theme.breakpoints.up('lg')]: {
            visibility:"visible",
        },
    },
    drawerPaperCloseHideTabs: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
        [theme.breakpoints.down('sm')]: {
            visibility:"hidden",
        },
        [theme.breakpoints.up('md')]: {
            visibility:"hidden",
        },
        [theme.breakpoints.up('lg')]: {
            visibility:"hidden",
        },
    },
    hideLink:{
        textDecoration:'none',
        color:'inherit'
    },
    rightBar:{
        position:'absolute',
        left:'90%',
    },
    toolbar: {
        paddingRight: 0, // keep right padding when drawer closed,
        marginRight:0,
        display: 'flex',
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 0px',
        ...theme.mixins.toolbar,
    },
    toolbarDiv: {
        display: 'flex',
        alignItems: 'Right',
        justifyContent: 'flex-end',
        padding: 0,
        margin:0,
        ...theme.mixins.toolbar,
    },
    signUp:{
        fontWeight:'normal',
        fontSize:'18px'
    },
    title: {
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            display:"none",
        },
        [theme.breakpoints.up('sm')]: {
            display:"block",
        },
        [theme.breakpoints.up('lg')]: {
            display:"block",
        },
    },
    imgIcon: {
        marginTop:0,
        marginBottom:0,
        paddingTop:0,
        paddingBottom:0,


        // flexGrow: 1,
    },
    menuButton: {
        marginRight: "2%",
    },
    menuButtonHidden: {
        display: 'none',
    },
    readyButton:{
        borderColor:'white', border:'2px solid white', borderRadius:0,
    },
    searchBar: {
        display: 'flex',
        alignItems: 'right',
        justifyContent: 'flex-end'
    },

}));

export default function IndexBar(props){

    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(null);
    const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(null);
    const [sendToConfirm,setSendToConfirm]= useState(false);
    const [startWithLogin,setStartWithLogin]= useState(false);
    const [hideLogin,setHideLogin]= useState(props.searchable);
    let navigate = useNavigate();


    useEffect(()=>{
        if(props.dialogOpen){
            setDialogOpen(props.dialogOpen)
        }
    },[props.dialogOpen])

    const openLoginDialog = useCallback(() => {
        setStartWithLogin(true);
        setDialogOpen("register");
        setIsMobileDrawerOpen(false);
    }, [setDialogOpen, setIsMobileDrawerOpen]);

    const closeDialog = useCallback(() => {
        setDialogOpen(null);
        if(props.setDialogOpen){props.setDialogOpen(null)}
    }, [setDialogOpen]);

    const openRegisterDialog = useCallback(() => {
        setStartWithLogin(false);
        setDialogOpen("register");
        setIsMobileDrawerOpen(false);
    }, [setDialogOpen, setIsMobileDrawerOpen]);


    const [open, setOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const getDrawerCloseClass = (hideTabs)=>{
        if(hideTabs){
            return classes.drawerPaperCloseHideTabs
        }
        return classes.drawerPaperClose
    };

    const openChangePasswordDialog = useCallback(() => {
        setDialogOpen("changePassword");
    }, [setDialogOpen]);

    useEffect(()=>{
        let _openRegisterDialog;
        let _sendToConfirm;

        try{
            _openRegisterDialog = window.location.href.split('signUp=')[1].split('&')[0] === 'true';
        }
        catch (e) {
        }

        try{
            _sendToConfirm = window.location.href.split('confirm=')[1].split('&')[0] === 'true';
            setSendToConfirm(_sendToConfirm);
        }
        catch (e) {
        }

        try{
            if(_openRegisterDialog || _sendToConfirm){
                openRegisterDialog()
            }
        }
        catch (e) {
            console.error(e)
        }
    },[])

    return (
        <div>
            <AppBar position="fixed" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    {/*<Hidden xsDown>*/}
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        href='/'
                        className={classes.imgIcon}
                    >
                        <img className={classes.imgIcon} src="/favicon.png" width={40} height={40} alt="Notifycam logo"/>
                    </IconButton>
                    {/*</Hidden>*/}
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        <a href='/' style={{textDecoration:'none',color:'#e6e6e6ff'}}>
                            {CONSTANTS.APP_NAME}
                        </a>
                    </Typography>



                    <div  style={{textOverflow:'clip',flexGrow:1,}} className={classes.toolbarDiv}>

                        {!hideLogin &&
                        <Hidden smUp>
                        <Typography  style={{textOverflow:'clip'}} component="h2" variant="h6" color="inherit" noWrap className={classes.toolbarIcon}>
                            {(!props.firstName && !props.noRegister) &&
                            <Button variant="outlined"  style={{color:"#e6e6e6ff",border:'none', borderRadius:0,marginRight:0}} onClick={()=>{openLoginDialog(true)}}>Log in</Button>
                            }
                        </Typography>
                            <Typography  style={{textOverflow:'clip'}} component="h2" variant="h6" color="inherit" noWrap className={classes.toolbarIcon}>
                                {(props.actionText)?
                                <Button variant="contained"  style={{backgroundColor:'#3b5ea8ff', color:'#e6e6e6ff',borderRadius:0,marginRight:10,marginLeft:0,border:'2px solid #e6e6e6ff'}} href={props.actionLink}>{props.actionText}</Button>
                                    :
                                    (!props.firstName)?
                                        props.noRegister?    null :
                                            <Button variant="contained"  style={{backgroundColor:'#3b5ea8ff', color:'#e6e6e6ff',borderRadius:0,marginRight:10,marginLeft:0,border:'2px solid #e6e6e6ff'}} onClick={()=>{
                                                openRegisterDialog(true)
                                                // navigate('/card');
                                            }}>Get started</Button>
                                    :
                                    <Button variant="contained"  style={{backgroundColor:'#3b5ea8ff', color:'#e6e6e6ff',borderRadius:0,marginRight:10,marginLeft:0,border:'2px solid #e6e6e6ff'}} href={'/'}>Dashboard</Button>
                                }

                            </Typography>
                        </Hidden>
                        }
                        <Hidden xsDown>
                            <Typography  style={{textOverflow:'clip'}} component="h2" variant="h6" color="inherit" noWrap className={classes.toolbarIcon}>
                                {(!props.firstName && !props.noRegister) &&
                                <Button variant="outlined"  style={{color:"#e6e6e6ff",border:'none', borderRadius:0,marginRight:0}} onClick={()=>{
                                    window.location.href = AUTH_URL;
                                }}>Log in</Button>
                                }
                            </Typography>
                            <Typography  style={{textOverflow:'clip'}} component="h2" variant="h6" color="inherit" noWrap className={classes.toolbarIcon}>
                                {(props.actionText)?
                                <Button variant="contained"  style={{backgroundColor:'#3b5ea8ff', color:'#e6e6e6ff',borderRadius:0,marginRight:10,marginLeft:0,border:'2px solid #e6e6e6ff'}}  href={props.actionLink}>{props.actionText}</Button>
                                    :
                                    (props.firstName)?
                                            <Button variant="contained"  style={{backgroundColor:'#3b5ea8ff', color:'#e6e6e6ff',borderRadius:0,marginRight:10,marginLeft:0,border:'2px solid #e6e6e6ff'}} href={'/'}>Dashboard</Button>
                                            :
                                            props.noRegister? null:
                                            <Button variant="contained"  style={{backgroundColor:'#3b5ea8ff', color:'#e6e6e6ff',borderRadius:0,marginRight:10,marginLeft:0,border:'2px solid #e6e6e6ff'}} onClick={()=>{
                                                // navigate('/card');
                                                openRegisterDialog(true)
                                            }}>Get started</Button>
                                }

                            </Typography>
                        </Hidden>
                    </div>
                </Toolbar>
            </AppBar>
            {/*</HideOnScroll>*/}
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && getDrawerCloseClass(props.hideTabs)),
                }}
                open={open}
            >

                <div className={classes.toolbarIcon}>
                    {props.firstName &&
                    <Typography>
                        Welcome {props.firstName}
                    </Typography>
                    }
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>{props.firstName? mainListItems : indexListItems(openRegisterDialog)}</List>
                <Divider />
                <List><SecondaryListItems/></List>
            </Drawer>
            <DialogSelector
                startWithLogin={startWithLogin}
                sendToConfirm={sendToConfirm}
                _csrf={props._csrf}
                openLoginDialog={openLoginDialog}
                dialogOpen={dialogOpen}
                selectPlan={props.selectPlan}
                onClose={closeDialog}
                openTermsDialog={()=>{}}
                openRegisterDialog={openRegisterDialog}
                openChangePasswordDialog={openChangePasswordDialog}
            />

        </div>
    )
}

import React, {Fragment} from "react";

export const WEBAPP_URL="https://taskfast.ca/";
export const LANDING_PAGE_URL="https://taskfast.ca/";
const DEV_MODE = process.env.REACT_APP_DEV_MODE === 'true';

export const FRONT_END_LOGOUT_DEV_MODE=DEV_MODE;
export const AUTH_URL=DEV_MODE?"http://localhost:8080/auth":`${process.env.REACT_APP_AUTH_URL}/?redirect=dashboard`;

export const APP_NAME=process.env.REACT_APP_APP_NAME;

export const INDEX_SUBTITLE=<Fragment>Connecting you with home service professionals</Fragment>; //
export const API_URI=DEV_MODE?"http://localhost:8080":process.env.REACT_APP_API_URI;
// export const AUTH_API_URI_LOGOUT=`${AUTH_URL}/leave`;
export const AUTH_API_URI_LOGOUT=DEV_MODE?"http://localhost:8080/auth":`${process.env.REACT_APP_AUTH_URL}/leave`;

export const TWITTER_URL="https://twitter.com/taskfast"

const _email = 'info@atstellar.com';//process.env.REACT_APP_EMAIL;
export const EMAIL=_email;
export const MAIL_TO=`mailto:${_email}`;
export const MIN_ACCOUNT_PAYOUT = 2000;
export const NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY=process.env.REACT_APP_NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY;
export const ID_SIZE = 12;
export const META_DESCRIPTION='Connecting home service teams with families. Sign up today for access reliable home service professionals. Get a free quote today.';
export const UserType = {Account:"Account", Customer:"Customer", Unknown:"Unknown"}



import React, {useEffect, useRef} from 'react';
import clsx from 'clsx';
import { makeStyles,alpha } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {mainListItems, mainListItemsWebsite, SecondaryListItems} from '../components/listItems';
import * as CONSTANTS from "../config/CONSTANTS";
import Copyright from "../components/Copyright";
import ListOptions from "../components/userPage/ListOptions"
import {userManager} from "../util/apiRequest";
import {UserType} from "../config/CONSTANTS";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor:theme.palette.background.appGray
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '20ch',
            '&:focus': {
                width: '22ch',
            },
        },
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        // marginRight: 36,
        marginRight: "2%",

    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        visibility:"visible",

    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
        [theme.breakpoints.down('sm')]: {
            width: 0,
            // visibility:"hidden",
            display:'none',
        },
        [theme.breakpoints.up('md')]: {
            width: theme.spacing(9),
            visibility:"visible",
        },
        [theme.breakpoints.up('lg')]: {
            width: theme.spacing(9),
            visibility:"visible",
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    imgIcon: {
        marginTop:0,
        marginBottom:0,
        paddingTop:0,
        paddingBottom:0
        // flexGrow: 1,
    },
}));

export default function UserPage(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [displayAppLogo, setDisplayAppLogo] = React.useState(true);
    const [userType, setUserType] = React.useState();
    const [token, setToken] = React.useState(window.localStorage.getItem("token"));

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(()=>{
        userManager.get(token,undefined,'/type').then(r=>{
            if(r.status_code === 200){
                setUserType(r.type)
            }
        }).catch(e=>{
            console.error("error",e);
        })
    },[])

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    {displayAppLogo &&
                    <React.Fragment>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            href='/'
                            className={classes.imgIcon}
                        >
                            {/*<img className={classes.imgIcon} src="/favicon.png" width={40} height={40}*/}
                            {/*     alt="Notifycam logo"/>*/}
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            <a href='/' style={{textDecoration: 'none', color: 'inherit'}}>
                                {CONSTANTS.APP_NAME}
                            </a>
                        </Typography>
                    </React.Fragment>
                    }
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <Typography>
                        Welcome {props.first_name}
                    </Typography>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>{(userType === UserType.Account) ? mainListItemsWebsite : mainListItems}</List>
                <Divider />
                <List><SecondaryListItems/></List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <ListOptions userType={userType} tpp={props.tpp} jumpToLogout={props.logout} jumpToChangePassword={props.changePassword} jumpToPlan={props.plan} trialActive={props.trialActive} failedDeletion={props.failedDeletion} pricingPlans={props.pricingPlans} csrfToken={props.csrfToken} />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Copyright />
                    </Box>
                </Container>
            </main>
        </div>
    );
}


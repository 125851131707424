import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ImageList, ImageListItem, ImageListItemBar } from '@material-ui/core';
import { visuallyHidden } from '@mui/utils';
import {AUTH_URL} from "../config/CONSTANTS";
import {appointmentManager} from "../util/apiRequest";
import {useNavigate} from "react-router-dom";
import TransitionsModal from "./ApptModal";
import {useEffect} from "react";



export default function Hero() {
    let navigate = useNavigate();
    const [booked, setBooked] = React.useState(false);
    const [emailErr, setEmaiErr] = React.useState(false);
    const [err, setErr] = React.useState();
    const [showConsultation, setShowConsultation] = React.useState(false);
    const [consultation, setConsultation] = React.useState({});
    const [loading, setLoading] = React.useState(false);

    

    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };


    useEffect(()=>{
        appointmentManager.get('',undefined,'/consultation').then(r=>{
            if(r.places_key){
                const elem = document.getElementById("places_script");
                if(!elem){
                    let tg = document.createElement('script');
                    tg.id="places_script";
                    tg.async=true;
                    tg.src=`https://maps.googleapis.com/maps/api/js?key=${r.places_key}&libraries=places&callback=initGMaps&loading=async`;
                    document.body.appendChild(tg)
                }
            }
        }).catch(e=>{console.error(e)})
    },[])

    const createConsultation = (service_name, address, selectedDate, onComplete) =>{
        if(window.localStorage.getItem('token')){
            navigate('/dashboard');
            return
        }
        setLoading(true);
        grecaptcha.enterprise.ready(async () => {
            const token = await grecaptcha.enterprise.execute('6LcQlSIqAAAAACbo59eALdZCXgmC2rGluAqS-9dE', {action: 'CONSULTATION'});
            appointmentManager.post('',
                {
                token:token,
                service_name:service_name,
                customer_address:address,
                datetime:selectedDate,
                customer_email:consultation?.customer_email,
            },'/consultation').then((r)=>{
                setLoading(false);
                if(r.status_code === 200 || r.status_code === 201){
                    onComplete()
                    setBooked(true)
                }
                else{
                  setErr("Failed to create Consultation, please try again");
                  onComplete()
                }
            }).catch(e=>{
                setLoading(false);
                if(e?.response?.data?.message){
                  setErr(e?.response?.data?.message);
                  onComplete()
                }else{
                  setErr("Failed to create Consultation, please try again");
                  onComplete()
                }
            })
        });

    }
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
            }}
          >
            One&nbsp;stop&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: 'inherit',
                color: 'primary.main',
                ...theme.applyStyles('dark', {
                  color: 'primary.light',
                }),
              })}
            >
                services
            </Typography>
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              width: { sm: '100%', md: '80%' },
            }}
          >
              Enjoy instant access to top-rated service providers in one place. From home maintenance to commercial jobs, TaskFast connects you to the best provider in your area.
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
            <InputLabel htmlFor="email-hero" sx={visuallyHidden}>
              Email
            </InputLabel>
            <TextField
              error={emailErr}
              helperText={emailErr && "Enter your email address"}
              id="email-hero"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your email address"
              placeholder="Your email address"
              fullWidth
              onChange={(v)=>{setConsultation({...consultation, customer_email:v?.target?.value})}}
              slotProps={{
                htmlInput: {
                  autoComplete: 'off',
                  'aria-label': 'Enter your email address',
                },
              }}
            />
              <Button sx={{ minWidth: 'fit-content' }} onClick={()=>{
                if(validateEmail(consultation.customer_email)){
                  setEmaiErr(false)
                  setShowConsultation(true)
                } else {
                  setEmaiErr(true)
                }
                }} variant="contained" color="primary">
                  Book a consultation
              </Button>
              <TransitionsModal err={err} loading={loading} consultation createConsultation={createConsultation} open={showConsultation} handleClose={()=>{setShowConsultation(false)}} title={'Book your consultation'} />
{/* 
            <Button onClick={()=>{
                window.localStorage.removeItem('account');
                window.localStorage.setItem('customer','true');
                window.location.href=AUTH_URL;
            }} variant="text">
              Sign-up for free
            </Button> */}
          </Stack>
        </Stack>
          <ImageList style={{marginTop:5, padding:5}} cols={1}>
              <ImageListItem style={{paddingTop:5}} key={1}>
                  <img  src={'/media/out.jpeg'} alt={'TaskFast Pressure washing service'}  />
              </ImageListItem>
          </ImageList>
      </Container>
    </Box>
  );
}

import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import {CardContent, CardHeader, CircularProgress} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {paymentManager} from "../../util/apiRequest";
import {MAIL_TO} from "../../config/CONSTANTS";
// import {fetchPlan} from "../../../../lib/clientUtil/apiManager";

const useStyles = makeStyles((theme) => ({
    creditCard:{
        fontSize:"12em",
        color:theme.palette.primary.dark
    },
    root:{
        maxWidth:600
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    cardHeader: {
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },

    cardEnterprisePricing:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
        paddingTop:'20px',
        paddingBottom:'15px',
        // paddingTop:'65px',

    },
}
));



function UpdateCard(props){
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [brand, setBrand] = useState();
    const [plan, setPlan] = useState();
    const [last4, setLast4] = useState();

    const getPlans = () =>{
        return paymentManager.get(window.localStorage.getItem("token"),undefined,'/customer').then(r=>{
            if(r.card){
                setBrand(r.card.cardBrand);
                setLast4(r.card.cardLast4);
                setPlan(r.plan)
                setLoading(false);
            }
            setLoading(false)
        })
    }

    useEffect(()=>{
        getPlans()
    },[]);

    return (
        <Container {...props} maxWidth="md" component="footer">
            {!loading?
                <div>
                    <Card className={classes.root} style={{marginBottom:20}}>
                        <CardHeader
                            title={plan.name}
                            subheader={"Current plan"}
                            titleTypographyProps={{ align: 'left' }}
                            subheaderTypographyProps={{ align: 'left' }}
                            style={{backgroundColor:'#f0f0f5'}}
                            className={classes.cardHeader}
                        />
                        <CardContent>
                            {plan.name === 'Enterprise'?
                                <div className={classes.cardEnterprisePricing}>
                                    <Typography variant="h4" style={{fontSize:'1.5em', fontWeight:'bold'}} color="textPrimary">
                                        Contact us
                                    </Typography>
                                </div>
                                :
                                <div className={classes.cardPricing}>
                                    <Typography component="h2" variant="h3" color="textPrimary">
                                        ${(plan.price/100).toFixed(2)}
                                    </Typography>
                                    <Typography style={{paddingLeft:6}} variant="h6" color="textSecondary">
                                        {"USD/month"}
                                    </Typography>
                                </div>
                            }

                            <ul>
                                {
                                    (plan.description || []).map((line,idx) => (
                                        <Typography component="li" variant="subtitle1" align="center" key={line}>
                                            {((idx===0) && (plan.name !== 'Enterprise'))?
                                                <b>{line}</b>
                                                :
                                                line
                                            }

                                        </Typography>
                                    ))
                                }
                            </ul>
                        </CardContent>
                        <CardActions>
                            <Button style={{marginLeft:15}} component='a' href='/user/plans?updatePlan=true' size="small" color="primary">
                                Change plan
                            </Button>
                        </CardActions>
                    </Card>

                    <Card className={classes.root}>
                        <CardContent>
                            <CreditCardIcon className={classes.creditCard}/>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Default payment method
                                </Typography>
                                <Typography style={{textTransform:'uppercase'}} variant="body2" color="textSecondary" component="p">
                                    {brand}
                                    <code style={{marginLeft:10}}>
                                        {"****-****-****-"} {last4}
                                    </code>
                                </Typography>
                            </CardContent>
                        </CardContent>
                        <CardActions>
                            <Button style={{marginLeft:15}} component='a' href='/user/card?update=true' size="small" color="primary">
                                Update payment method
                            </Button>
                        </CardActions>
                    </Card>
                </div>


                :
                <Grid container fullWidth spacing={2} justify="center">
                    <Grid item>
                        <CircularProgress style={{}} size={28} />
                    </Grid>
                </Grid>

            }


        </Container>
    )
}



export default UpdateCard;

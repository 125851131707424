import axios from "axios";
import {API_URI, AUTH_URL, EDGE_API_URI} from "../config/CONSTANTS";

const send = (method, token, path, params, data) => {
    if(method === 'post'){
        return axios.post(path, data,{
            headers: {
                'Content-Type': 'application/json',
                authorization: token,
            },
            withCredentials: true,
            crossDomain: true,
        })
    }
    return axios[method](
        path,
        {
            headers: {
                authorization: token,
            },
            params:params,
            withCredentials: true,
            crossDomain: true,
        }
    )
};



function handleAPIResponse(r){
    if(!window){
        return r.data;
    }
    let status = 200;
    let err=false;
    if(r && r.data && r.data.status_code){
        status = r.data.status_code;
    } else if(r && r.response && r.response.status){
        status = r.response.status
        err=true
    } else {
        console.error("Fail to find status")
    }
    if(status === 401){
        let path=window.location.pathname;
        if(path !== '/testlogin'){
            if(path && path.length > 1 &&  path.startsWith('/')){
                window.location.href=`${AUTH_URL}?redirect=${path.slice(1)}`
            } else {
                window.location.href=AUTH_URL
            }
        }
    } else if(status === 402) {
        if(window.location.pathname !== '/card'){
            window.location.href = '/card';
        }
    }
    return err? {status_code:status} : r.data
}

class APIManager {
    constructor(resource, baseUri=API_URI) {
        this.api_uri = baseUri + '/'+ resource;
        this.inj_sender = send;
    }
    mock(status){
        this.inj_sender = (method, token, path, params, data) =>{
            return Promise.resolve({response:{status}, data:{status_code:status}})
        }
    }
    unmock(){
        this.inj_sender = send;
    }
    get(token, id, pathSuffix) {
        return this.inj_sender('get', token,`${this.api_uri}${pathSuffix?pathSuffix:''}`, id? {id}: undefined).then(r=>{
            return handleAPIResponse(r);
        }).catch(e=>{
            console.error("failed request",e);
            return handleAPIResponse(e)
        })
    }
    unAuthGet(pathSuffix) {
        return axios.get(`${this.api_uri}${pathSuffix?pathSuffix:''}`)
    }
    post(token, data, pathSuffix) {
        return this.inj_sender('post', token, `${this.api_uri}${pathSuffix?pathSuffix:''}`, {}, data).then(r=>{
            return r.data;
        })
    }
    del(token, id, pathSuffix) {
        return this.inj_sender('delete', token, `${this.api_uri}${pathSuffix?pathSuffix:''}`, {id}).then(r=>{
            return r.data;
        })
    }
}
export const rootManager = new APIManager('');
export const paymentManager = new APIManager('card');
export const userManager = new APIManager('user');
export const serviceManager = new APIManager('service');
export const appointmentManager = new APIManager('appointment');


import React, {Fragment, useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core/styles";
import ObjectComplete from "../Autocomplete";
import Button from "@material-ui/core/Button";
import Title from "../Title";


const useStyles = makeStyles((theme) => ({
    buttons: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    centerVertically:{
        // minHeight:'50%',
        margin:'auto'
    },
    checkbox: {
        // width: 450,
        // maxWidth:'60vw',
        // '& > * + *': {
        //     marginTop: theme.spacing(3),
        // },
        alignItems:'flex-start',
        marginRight:'auto',
        marginLeft:'12vw',
        marginTop:10
    }
}));


export default function SearchEvents(props) {
    const classes = useStyles();
    let shouldDisable = props.checkOtherInput? !(props.eventSelected && props.eventSelected.length && props.otherInput):
        !(props.eventSelected && props.eventSelected.length)


    const [nextDisabled, setNextDisabled] = useState(shouldDisable);
    useEffect(()=>{
        shouldDisable = props.checkOtherInput? !(props.eventSelected && props.eventSelected.length && props.otherInput):
            !(props.eventSelected && props.eventSelected.length)
        setNextDisabled(shouldDisable)
    },[props.eventSelected, props.otherInput])

    const onEventSelected = (lo)=>{
        let ds = props.checkOtherInput? !(lo && lo.length && props.otherInput) : !(lo && lo.length)
        setNextDisabled(ds)
        props.onEventSelected(lo)
    }

    return (
        <React.Fragment>
            {!props.no_title &&
            <Title>
                {props.label}
            </Title>
            }

            <Grid   container
                    className={classes.centerVertically}
                    direction="column"
                    alignItems="center"
                    justifyContent="center">
                <Grid style={{display:'flex'}} item>
                    <ObjectComplete hardcode={props.hardcode} must_find={props.must_find} label={props.label} ready={props.ready} allowMultiple={false} exact={true} defaultValue={props.defaultValue} eventSelected={props.eventSelected} onEventSelected={onEventSelected}/>
                        <Button
                            disabled={nextDisabled}
                            variant="contained"
                            color="primary"
                            onClick={props.handleNext}
                        >
                            {"Next"}
                        </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

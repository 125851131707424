
import React, {useEffect, useRef} from 'react';
import clsx from 'clsx';
import { makeStyles,alpha } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems,mainListItemsWebsite, SecondaryListItems } from '../components/listItems';
import * as CONSTANTS from "../config/CONSTANTS";
import Copyright from "../components/Copyright";
import ListOptions from "../components/userPage/ListOptions"
import {appointmentManager, paymentManager, userManager} from "../util/apiRequest";
import {AUTH_URL, UserType} from "../config/CONSTANTS";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import EventNoteIcon from '@material-ui/icons/EventNote';
import {useNavigate} from "react-router-dom";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor:theme.palette.background.appGray
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '20ch',
            '&:focus': {
                width: '22ch',
            },
        },
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        // marginRight: 36,
        marginRight: "2%",

    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        visibility:"visible",

    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
        [theme.breakpoints.down('sm')]: {
            width: 0,
            // visibility:"hidden",
            display:'none',
        },
        [theme.breakpoints.up('md')]: {
            width: theme.spacing(9),
            visibility:"visible",
        },
        [theme.breakpoints.up('lg')]: {
            width: theme.spacing(9),
            visibility:"visible",
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    imgIcon: {
        marginTop:0,
        marginBottom:0,
        paddingTop:0,
        paddingBottom:0
        // flexGrow: 1,
    },
}));


function CustomDialog(props) {
    const handleClose = () => {
        props.onClose()
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={props.show}>
            <DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
            <List>
                <ListItem autoFocus button onClick={props.onClick}>
                    <ListItemAvatar>
                        {props.icon}
                    </ListItemAvatar>
                    <ListItemText primary={props.buttonText} />
                </ListItem>
            </List>
        </Dialog>
    );
}

export default function AppointmentPage(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [displayAppLogo, setDisplayAppLogo] = React.useState(true);
    let _userType;
    if(window.localStorage.getItem('customer')){
        _userType =UserType.Customer;
    } else if(window.localStorage.getItem('account')){
        _userType =UserType.Account;
    }
    const [userType, setUserType] = React.useState(_userType);
    const [token, setToken] = React.useState(window.localStorage.getItem("token"));

    const [upcomingAppt, setUpcomingAppt] =React.useState();
    const [waitingAppt, setWaitingAppt] =React.useState();
    const [completedAppt, setCompletedAppt] =React.useState();
    const [customerMember, setCustomerMember] = React.useState();
    const [connectedAccount, setConnectedAccount] = React.useState();
    const [message, setMessage] = React.useState();
    const [showDialog, setShowDialog] = React.useState(false);
    const [showCompleteDialog, setShowCompleteDialog] = React.useState(false);

    let navigate = useNavigate();


    useEffect(()=>{
        findUserType()
    },[])

    const sendToBilling = () => {
        paymentManager.get(window.localStorage.getItem("token"),undefined,'/billing').then(pr=>{
            if(pr.target_url){
                window.location.href=pr.target_url;
            }
        }).catch(e=>{
            console.error("/card/billing error",e);
        })
    };

    const findUserType= ()=>{
        userManager.get(window.localStorage.getItem("token"),undefined,'/type').then(r=>{
            if (r.status_code === 401){
                window.location.href=AUTH_URL;
            } else if(r.status_code === 200){
                if(r.redirect_to_billing){
                    sendToBilling()
                } else {

                    let _userType = r.type;
                    if(r.type === UserType.Unknown){
                        if(window.localStorage.getItem('customer')){
                            _userType =UserType.Customer;
                        } else if(window.localStorage.getItem('account')){
                            _userType =UserType.Account;
                        }
                    }
                    setUserType(_userType);

                    setCustomerMember(r.member);
                    setConnectedAccount(r.connected);
                    fetchApptList(_userType)

                }
            }
        }).catch(e=>{
            console.error("/user/type error",e);
        })
    }

    const fetchApptList = async (tp)=>{
        if(tp === UserType.Account){
            appointmentManager.get(window.localStorage.getItem("token"),undefined,`/provider`).then(r=>{
                if(r.status_code === 200){
                    // r.lo_appt = r.lo_appt.map(i=>{
                    //     i.completed_datetime = new Date().toISOString();
                    //     return i
                    // })
                    setUpcomingAppt(r.lo_appt.filter(i=>(!i.completed_datetime && i.schedule_accepted_by_provider === true)))
                    setWaitingAppt(r.lo_appt.filter(i=>(!i.completed_datetime && i.schedule_accepted_by_provider === false)))
                    setCompletedAppt(r.lo_appt.filter(i=>(!!i.completed_datetime)))

                }
            });
        }else if(tp === UserType.Customer){
            appointmentManager.get(window.localStorage.getItem("token"),undefined,`/customer`).then(r=>{
                if(r.status_code === 200){
                    // r.lo_appt = r.lo_appt.map(i=>{
                    //     i.completed_datetime = new Date().toISOString();
                    //     return i
                    // })
                    setUpcomingAppt(r.lo_appt.filter(i=>(!i.completed_datetime && i.schedule_accepted_by_provider === true)))
                    setWaitingAppt(r.lo_appt.filter(i=>(!i.completed_datetime && i.schedule_accepted_by_provider === false)))
                    setCompletedAppt(r.lo_appt.filter(i=>(!!i.completed_datetime)))

                }
            });
        } else {
            setUpcomingAppt([])
            setWaitingAppt([])
            setCompletedAppt([])
        }
    }

    const removeAppt = (appt) =>{
        appointmentManager.post(window.localStorage.getItem('token'), {
            appt_id:appt.appt_id
        },'/remove').then((r)=>{
            if(r.status_code === 200){
                setMessage('Appointment deleted');
                fetchApptList(userType)
            }
            else{
                setMessage("Failed to delete Appointment, please try again")
            }
        }).catch(e=>{
            setMessage("Failed to delete Appointment, please try again")
        })
    }
    const acceptAppt = (appt) =>{
        appointmentManager.post(window.localStorage.getItem('token'), {
            appt_id:appt.appt_id
        },'/accept').then((r)=>{
            if(r.status_code === 200){
                setMessage('Appointment accepted');
                fetchApptList(userType)
            }
            else{
                setMessage("Failed to accept Appointment, please try again")
            }
        }).catch(e=>{
            if(e?.response?.data?.message){
                setMessage(e?.response?.data?.message)
            }else{
                setMessage("Failed to accept Appointment, please try again");
            }
        })
    }

    const completeAppt = (appt) =>{
        appointmentManager.post(window.localStorage.getItem('token'), {
            appt_id:appt.appt_id
        },'/complete').then((r)=>{
            if(r.status_code === 200){
                setMessage('Appointment completed');
                fetchApptList(userType)
            }
            else{
                setMessage("Failed to complete Appointment, please try again")
            }
        }).catch(e=>{
            if(e?.response?.data?.message){
                setMessage(e?.response?.data?.message)
            }else{
                setMessage("Failed to complete Appointment, please try again")
            }
        })
    }

    const payToConfirmAppt = (appt) => {
        paymentManager.get(window.localStorage.getItem("token"),undefined,`/pay?id=${appt.appt_id}`).then(pr=>{
            if(pr.target_url){
                window.location.href=pr.target_url;
            }
        }).catch(e=>{
            console.error("/card/billing error",e);
        })
    };


    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };


    const myUpcomingList = () =>{
        return (
            <List subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    Upcoming appointments
                </ListSubheader>
            }>
                {(upcomingAppt || []).map((i)=>(
                    <ListItem style={{color:'black'}}>
                        <ListItem >
                            {((userType === UserType.Customer) && !i.customer_paid) &&
                            <Button style={{backgroundColor:'green', color:'white', marginRight:10}} onClick={()=>{payToConfirmAppt(i)}} edge="start" aria-label="confirm">
                                Pay to confirm
                            </Button>
                            }
                            {((userType === UserType.Account) && !i.completed_datetime) &&
                                <div>
                                    <CustomDialog icon={<CheckBoxIcon style={{color:'green'}}/>} onClose={()=>{setShowCompleteDialog(false)}} show={showCompleteDialog} onClick={()=>{completeAppt(i);setShowCompleteDialog(false)}} title={'Are you sure?'} buttonText={'Complete appointment'}/>
                                    <Button style={{backgroundColor:'orange', color:'white', marginRight:10, fontSize:'0.5em'}} onClick={()=>{setShowCompleteDialog(true)}} edge="start" aria-label="confirm">
                                        Complete this service
                                    </Button>
                                </div>
                            }

                            <ListItemAvatar>
                                <Avatar>
                                    <EventNoteIcon  style={{color:'blue'}} />
                                </Avatar>
                            </ListItemAvatar>

                            <ListItemText
                                primary={`${i.service_name} on ${new Date(i.datetime).toLocaleString()}`}
                                secondary={`${i.customer_address} for $${(i.quote/100).toFixed(2)} (${i.sqft} sqft)`}
                            />

                            {((userType === UserType.Account) && i.customer_paid) &&
                            <ListItemSecondaryAction>
                            <Typography style={{color:'white',backgroundColor:'green', padding:2, fontSize:'0.6em', marginTop:20}}>Customer paid</Typography>
                            </ListItemSecondaryAction>
                            }


                            {(!i.customer_paid) &&
                            <ListItemSecondaryAction>
                                <CustomDialog icon={<DeleteIcon/>} onClose={()=>{setShowDialog(false)}} show={showDialog} onClick={()=>{removeAppt(i);setShowDialog(false)}} title={'Are you sure?'} buttonText={'Remove appointment'}/>
                                <IconButton onClick={()=>{setShowDialog(true)}} edge="end" aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                            }

                        </ListItem>
                    </ListItem>
                ))}
            </List>
        )
    }

    const myAwaitingList = () =>{
        return (
            <List subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    Awaiting confirmation
                </ListSubheader>
            }>
                {(waitingAppt || []).map((i)=>(
                    <ListItem style={{color:'black'}}>
                        <ListItem >
                            {((userType === UserType.Account) && !i.schedule_accepted_by_provider) &&
                            <Button style={{backgroundColor:'green', color:'white', marginRight:10}} onClick={()=>{acceptAppt(i)}} edge="start" aria-label="confirm">
                                Confirm
                            </Button>
                            }
                            {((userType === UserType.Customer)) &&
                            <ListItemAvatar>
                                <Avatar>
                                    <HourglassEmptyIcon  style={{color:'white'}} />
                                </Avatar>
                            </ListItemAvatar>
                            }
                            <ListItemText
                                primary={`${i.service_name} on ${new Date(i.datetime).toLocaleString()}`}
                                secondary={<a target="_blank" rel="noopener noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${i.customer_address}&query_place_id=${i.customer_place_id}`}>{`${i.customer_address} for $${(i.quote/100).toFixed(2)} (${i.sqft} sqft)`}</a>}
                            />
                            <ListItemSecondaryAction>
                                <CustomDialog icon={<DeleteIcon/>} onClose={()=>{setShowDialog(false)}} show={showDialog} onClick={()=>{removeAppt(i)}} title={'Are you sure?'} buttonText={'Remove appointment'}/>
                                <IconButton onClick={()=>{setShowDialog(true)}} edge="end" aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </ListItem>
                ))}
            </List>
        )
    }

    const myCompletedList = () =>{
        return (
            <List subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    Completed appointments
                </ListSubheader>
            }>
                {(completedAppt || []).map((i)=>(
                    <ListItem style={{color:'black'}}>
                        <ListItem >
                            <ListItemAvatar>
                                <Avatar>
                                    <CheckBoxIcon  style={{color:'green'}} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${i.service_name} on ${new Date(i.datetime).toLocaleString()}`}
                                secondary={`${i.customer_address} for $${(i.quote/100).toFixed(2)} (${i.sqft} sqft)`}
                            />

                        </ListItem>
                    </ListItem>
                ))}
            </List>
        )
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    {displayAppLogo &&
                    <React.Fragment>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            href='/'
                            className={classes.imgIcon}
                        >
                            {/*<img className={classes.imgIcon} src="/favicon.png" width={40} height={40}*/}
                            {/*     alt="Notifycam logo"/>*/}
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            <a href='/' style={{textDecoration: 'none', color: 'inherit'}}>
                                {CONSTANTS.APP_NAME}
                            </a>
                        </Typography>
                    </React.Fragment>
                    }
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <Typography>
                        Welcome {props.first_name}
                    </Typography>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>{(userType === UserType.Account) ? mainListItemsWebsite : mainListItems}</List>
                <Divider />
                <List><SecondaryListItems/></List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />

                <Container maxWidth="lg" className={classes.container}>
                    {/*<Button style={{backgroundColor:'yellow'}} onClick={()=>{setUserType(UserType.Customer);fetchApptList(UserType.Customer)}}>View as customer</Button>*/}
                    {/*<Button style={{backgroundColor:'blue'}} onClick={()=>{setUserType(UserType.Account);fetchApptList(UserType.Account)}}>View as provider</Button>*/}
                    {message && <Typography style={{marginTop:20,color:'red'}}>{message}</Typography>}
                    {myUpcomingList()}
                    {myAwaitingList()}
                    {myCompletedList()}

                    <Box pt={4}>
                        <Copyright />
                    </Box>
                </Container>
            </main>
        </div>
    );
}


import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import HomeIcon from '@material-ui/icons/Home';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import MessageIcon from '@material-ui/icons/Message';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import WebIcon from '@mui/icons-material/Web';
import ListIcon from '@material-ui/icons/List';
import DateRangeIcon from '@material-ui/icons/DateRange';
import BuildIcon from '@material-ui/icons/Build';

const linkStyle = {
    color: "inherit",
    textDecoration:'none'
}

const sideItems = [
    {
        icon:<HomeIcon/>,//< DashboardIcon />,
        name:"Dashboard",
        linkNav:'/dashboard'
    },
    {
        icon:<ListIcon />,
        name:"Appointments",
        linkNav:'/appointments'
    },
    {
        icon:<DateRangeIcon />,
        name:"Calendar",
        linkNav:'/calendar'
    },
    {
        icon:<PersonIcon />,
        name:"User",
        linkNav:'/user'
    },
]

const sideItemsWebsite = [
    {
        icon:<HomeIcon/>,//< DashboardIcon />,
        name:"Dashboard",
        linkNav:'/dashboard'
    },
    {
        icon:<ListIcon />,
        name:"Appointments",
        linkNav:'/appointments'
    },
    {
        icon:<DateRangeIcon />,
        name:"Calendar",
        linkNav:'/calendar'
    },
    {
        icon:<BuildIcon />,
        name:"Services",
        linkNav:'/services'
    },
    {
        icon:<PersonIcon />,
        name:"User",
        linkNav:'/user'
    },
]

// export const mainListItems = (

export const mainListItems = (
    <div>
        {
            sideItems.map((item,idx)=>{
                return(
                    <a key={idx} style={linkStyle} href={item.linkNav}>
                        <ListItem button>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItem>
                    </a>
                )
            })
        }
    </div>
);

export const mainListItemsWebsite = (
    <div>
        {
            sideItemsWebsite.map((item,idx)=>{
                return(
                    <a key={idx} style={linkStyle} href={item.linkNav}>
                        <ListItem button>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItem>
                    </a>
                )
            })
        }
    </div>
);



const indexSideItems = [
    {
        icon:<SearchIcon/>,
        name:"Find a service",
        linkNav:'/#'
    },
    {
        icon:<PersonIcon />,
        name:"Get started",
        linkNav:'/#signup'
    },
    {
        icon:<AttachMoneyOutlinedIcon />,
        name:"Plans & pricing",
        linkNav:'/#pricing'
    },
    {
        icon: <MessageIcon/>,
        name:"Learn more",
        linkNav:'/#learn-more'
    },

]

// export const mainListItems = (

export const indexListItems =(f)=> (
    <div>
        {
            indexSideItems.map((item,idx)=>{
                return(
                    <a key={idx} style={linkStyle} href={item.name==='Sign up'? undefined: item.linkNav}>
                        <ListItem onClick={()=>{
                            if(item.name==='Sign up'){
                                f()
                            }
                        }} button>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItem>
                    </a>
                )
            })
        }


    </div>
);

const secondarySideItems = [
    // {
    //     name:'Pressure washing',
    //     icon:<PageviewOutlinedIcon/>,
    //     linkNav:'/#pressure_washing'
    // },

];

const useStyles = makeStyles((theme) => ({
    secondaryText:{
        fontSize:'12px',
    }
}));

export const SecondaryListItems = (props) =>{
    const classes = useStyles();
    return (
        <div>
            <ListSubheader inset>Resources</ListSubheader>

            {
                secondarySideItems.map((item,idx)=>{
                    return(
                        <a key={idx} style={linkStyle} href={item.linkNav} target="_blank" rel="noopener noreferrer">
                            <ListItem button>
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                    <React.Fragment>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            className={classes.secondaryText}
                                            color="textSecondary"
                                        >
                                            {item.name}
                                        </Typography>
                                    </React.Fragment>
                                } />
                            </ListItem>
                        </a>
                    )
                })
            }


        </div>
    );
}


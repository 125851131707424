import React, {Fragment, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import LocationSearchInput from "../components/AddEventSteps/addLocation";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import SearchEvents from "../components/AddEventSteps/SearchEvents";
import Scheduler from "./Scheduler";
import {appointmentManager, userManager} from "../util/apiRequest";
import { FRONT_END_LOGOUT_DEV_MODE } from '../config/CONSTANTS';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    stepper:{
        marginLeft:0,
        paddingLeft:0,
        overflowX:'auto'
    },
}));
const selectedSteps = ['Find the service you need', 'Address to be serviced','Choose a date'];

export default function TransitionsModal(props) {
    const classes = useStyles();
    const [message, setMessage] = React.useState();
    const [selectedDate, setSelectedDate] = React.useState();
    const [activeStep, setActiveStep] = React.useState(0);
    const [selectedServiceName, setSelectedServiceName] = React.useState();
    const [address, setAddress] = React.useState();

    const completeLastStep = () => {
        if(props.consultation){
            props.createConsultation(selectedServiceName, address, selectedDate, handleNext)
        } else {
            handleNext()
        }
    };


    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const getServiceFinder = (label, inputLabel, mustFind, hardcode) =>{
        return <Fragment>
            <SearchEvents hardcode={hardcode} must_find={mustFind} label={label}  max_events_per_device={1} editMode={false} ready={true}
                          eventSelected={selectedServiceName}  handleBack={handleBack} handleNext={handleNext}
                          activeStep={activeStep} onEventSelected={(ev)=>{ev?.length && setSelectedServiceName(ev)}} />
        </Fragment>;
    }

    const getFinalStep = () => {
        if(message){
            return (<Typography>{message}</Typography>)
        } else if(props.consultation){
            return (<Typography>{props.err? props.err : "Your consultation is booked. You'll receive a confirmation email from TaskFast"}</Typography>)
        } else {
            return (<Typography>{'Loading...'}</Typography>)
        }
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return getServiceFinder("Find the service you need","Find the service you need",true, true)
            case 1:
                return (<Fragment>
                    <LocationSearchInput onButtonClick={(address, placeId)=>{
                    setAddress({address, placeId})
                    handleNext()
                }} label={'Enter the address to be serviced'}/>
                {FRONT_END_LOGOUT_DEV_MODE && <button onClick={()=>{setAddress({address:'128 St, Surrey, BC, Canada', placeId:'EhoxMjggU3QsIFN1cnJleSwgQkMsIENhbmFkYSIuKiwKFAoSCUVcPOOY2YVUEfF7y3svn8DHEhQKEglbpz9NA9yFVBHgmNWrmEiL0g'});handleNext()}}>Skip</button>}
                </Fragment>)
            case 2:
                return (<Scheduler loading={props.loading} selectedDate={selectedDate} setSelectedDate={setSelectedDate} handleNext={completeLastStep}/>)
            case 3:
                return getFinalStep()
            default:
                throw new Error('Unknown step');
        }
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">{props.title}</h2>
                        {/*<p id="transition-modal-description"></p>*/}
                        <Stepper activeStep={activeStep} className={classes.stepper}>
                            {
                                selectedSteps.map((label, index) => {
                                    const labelProps = {};
                                    return (
                                        <Step key={label}>
                                            <StepLabel  {...labelProps}
                                                        StepIconProps={{classes: {completed: classes.completed}}}>{label}</StepLabel>
                                        </Step>
                                    )
                                })
                            }
                            <br/>
                        </Stepper>
                        {getStepContent(activeStep)}
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

import React, {Fragment, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import {CircularProgress, TextField} from "@material-ui/core";
import VisibilityPasswordTextField from "./VisibilityPasswordTextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DevicesIcon from "@material-ui/icons/Devices";
import ListItemText from "@material-ui/core/ListItemText";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import {makeStyles} from "@material-ui/core/styles";
import {FacebookButton, GoogleButton, InstagramButton, PersonButton, SlackButton} from "../SocialButtons";
import {userManager} from "../../util/apiRequest";
import WebIcon from '@mui/icons-material/Web';
import ImageIcon from '@mui/icons-material/Image';
import {AUTH_URL} from "../../config/CONSTANTS";

const useStyles = makeStyles({
    depositContext: {
        flex: 1,
    },
    listRoot: {
        width: '100%',
        // maxWidth: 360,
//         alignItems: "center",
// justifyContent: "center"
    },
});

const socialList=[
    {name:'Use phone or email instead',icon:PersonButton,notSocial:true},
    {name:'Continue with Facebook',icon:FacebookButton, nav:'/auth/facebook'},
    {name:'Continue with Google',icon:GoogleButton, nav:'/auth/google'},
    // {name:'Continue with Instagram',icon:InstagramButton, nav:'/auth/instagram' },
    // {name:'Continue with Slack',icon:SlackButton, nav:'/auth/slack' }

]


export default function OauthList(props) {
    const classes = useStyles();

    return (
        <Fragment>
            <List className={classes.listRoot} aria-label="main social signup">
                <ListItem button  component="a"
                          onClick={(e)=>{
                              window.localStorage.setItem('destination','customer');
                              window.location.href = AUTH_URL;
                          }}
                          style={{width:'100%',textAlign:'center',marginTop:'5%'}} alignItems="center">
                    <ListItemIcon>
                        <ImageIcon />
                    </ListItemIcon>
                    <ListItemText primary={
                        <div style={{fontSize:'1.4em'}}>
                            Find a home service provider
                        </div>
                    }/>
                </ListItem>
                    <ListItem button  component="a" onClick={(e)=>{
                        window.localStorage.setItem('destination','account');
                        window.location.href = AUTH_URL;
                    }} style={{width:'100%',textAlign:'center',marginTop:'5%'}} alignItems="center" >
                        <ListItemIcon>
                            <WebIcon />
                        </ListItemIcon>
                        <ListItemText  primary={
                            <div style={{fontSize:'1.4em'}}>
                                Join as a home service provider
                            </div>
                        }/>
                    </ListItem>

            </List>
        </Fragment>
    );
}


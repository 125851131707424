import { createTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

// border
const borderWidth = 2;
const borderColor = "rgba(0, 0, 0, 0.13)";
// grey #e6e6e6ff
// red #a83b5eff
// blue #3b5ea8ff
// monochomatic off shade of blue #4f638e
// Create a theme instance. .
const theme = createTheme({
  palette: {
    primary: {
      main: '#6194ff',//#556cd6
    },
    secondary: {
      main: '#ff7a00',//#19857b
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
      appGray:'#e6e6e6ff',
      monochromeOffBlue:'#4488ff'
    },
  },
  border: {
    borderColor: borderColor,
    borderWidth: borderWidth
  },
  typography: {
    //"fontFamily": `"Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif`,
    // "fontFamily": `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,sans-serif`,
    // "fontFamily": `"Sofia Pro", "Helvetica Neue", "Helvetica", "Arial", sans-serif`,
    "fontFamily": `"Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif`,
    "fontSize": 15,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 600,
    button: {
      textTransform: 'none'
    }
  }
});

export default theme;

import React, {Fragment, useEffect, useRef, PureComponent, useState} from 'react';
import clsx from 'clsx';
import { makeStyles,alpha } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {mainListItems, mainListItemsWebsite, SecondaryListItems} from '../components/listItems';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import * as CONSTANTS from "../config/CONSTANTS";
import Copyright from "../components/Copyright";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {CircularProgress, InputAdornment} from "@material-ui/core";
import {appointmentManager, paymentManager, serviceManager, userManager} from "../util/apiRequest";
import {useNavigate} from "react-router-dom";
import {AUTH_URL, UserType} from "../config/CONSTANTS";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import SearchEvents from "../components/AddEventSteps/SearchEvents";
import LocationSearchInput from "../components/AddEventSteps/addLocation";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {goodDate} from "../util/bookingUtil";
import Scheduler from "../v2components/Scheduler";

const drawerWidth = 240;
const steps = ['Find the service you need', 'Address to be serviced', "Enter the property square foot",'Book your appointment','Add your payment method'];

const providerSteps = ['Add your service', 'your business address', 'Estimate your quote','Link your account'];

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor:theme.palette.background.appGray
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    stepper:{
        marginLeft:0,
        paddingLeft:0,
        overflowX:'auto'
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '20ch',
            '&:focus': {
                width: '22ch',
            },
        },
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        // marginRight: 36,
        marginRight: "2%",

    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        visibility:"visible",

    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
        [theme.breakpoints.down('sm')]: {
            width: 0,
            // visibility:"hidden",
            display:'none',
        },
        [theme.breakpoints.up('md')]: {
            width: theme.spacing(9),
            visibility:"visible",
        },
        [theme.breakpoints.up('lg')]: {
            width: theme.spacing(9),
            visibility:"visible",
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 350,
    },
    fixedHeightMax:{
        height: 480,
    },
    imgIcon: {
        marginTop:0,
        marginBottom:0,
        paddingTop:0,
        paddingBottom:0
    },
    businessName: {
        width: 450,
        maxWidth:'60vw',
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
        marginRight:5,
        color: 'inherit',
    }
}));




export default function Dashboard(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [displayAppLogo, setDisplayAppLogo] = React.useState(true);
    let _userType;
    if(window.localStorage.getItem('customer')){
        _userType =UserType.Customer;
    } else if(window.localStorage.getItem('account')){
        _userType =UserType.Account;
    }
    const [userType, setUserType] = React.useState(_userType);
    const [customerMember, setCustomerMember] = React.useState();
    const [connectedAccount, setConnectedAccount] = React.useState();
    const [activeStep, setActiveStep] = React.useState(0);


    const [selectedServiceName, setSelectedServiceName] = React.useState();
    const [address, setAddress] = React.useState();
    const [quotePerSqft, setQuotePerSqft] = React.useState();
    const [currency, setCurrency] = React.useState('CAD');
    const [message, setMessage] = React.useState();
    const [loProvider, setLoProvider] = React.useState([]);
    const [selectedProvider, setSelectedProvider] = React.useState();
    const [selectedDate, setSelectedDate] = React.useState();
    const [propertySqft, setPropertySqft] =React.useState();
    const [businessName, setBusinessName] =React.useState();
    const [placesKey, setPlacesKey] =React.useState();

    let selectedSteps = [];
    if(userType === UserType.Account){
        selectedSteps = providerSteps;
    } else if(userType === UserType.Account){
        selectedSteps = steps;
    }

    let navigate = useNavigate();


    useEffect(()=>{
        let params = (new URL(window.location.href)).searchParams;
        // Customer params
        const paramSubscribed= params.get('subscribed') === 'true';
        const paramCancelled = params.get('cancelled') === 'true';

        // Account params
        let code = params.get('code');
        let state = params.get('state');

        if(paramSubscribed){
            console.log("Customer SUBSCRIBED!");
        } else if (paramCancelled){
            console.log("Customer CANCELLED!");
        }
        if(code?.length && state?.length){
            // Better be localStorage or re-pushed through authentication redirect? @TODO
            paymentManager.get(window.localStorage.getItem("token"),undefined,`/token?code=${code}&state=${state}`).then((r)=>{
                if(r.status_code === 200){
                    console.log("Account LINKED!");
                    findUserType();
                }
            }).catch(e=> {
                console.error(e);
            })
        } else {
            findUserType()
        }

    },[])

    const findUserType= ()=>{
        userManager.get(window.localStorage.getItem("token"),undefined,'/type').then(r=>{
            if (r.status_code === 401){
                window.location.href=AUTH_URL;
            } else if(r.status_code === 200){
                if(r.redirect_to_billing){
                    sendToBilling()
                } else {
                    let _userType = r.type;
                    if(r.type === UserType.Unknown){
                        if(window.localStorage.getItem('customer')){
                            _userType =UserType.Customer;
                        } else if(window.localStorage.getItem('account')){
                            _userType =UserType.Account;
                        }
                    }
                    setUserType(_userType);
                    setCustomerMember(r.member);
                    setConnectedAccount(r.connected);
                    if(r.business_name){
                        setBusinessName(r.business_name)
                    }
                    if(r.address){
                        setAddress({address:r.address,placeId:r.placeId})
                    }
                    setPlacesKey(r.places_key);
                    if(r.places_key){
                        const elem = document.getElementById("places_script");
                        if(!elem){
                            let tg = document.createElement('script');
                            tg.id="places_script";
                            tg.async=true;
                            tg.src=`https://maps.googleapis.com/maps/api/js?key=${r.places_key}&libraries=places&callback=initGMaps&loading=async`;
                            document.body.appendChild(tg)
                        }
                    }
                }
            }
        }).catch(e=>{
            console.error("/user/type error",e);
        })
    }

    const acquireLink= ()=>{
        paymentManager.get(window.localStorage.getItem("token"),undefined,'/redirectUri').then((r)=>{
            if(r.status_code === 200){
                window.location.href = r.redirect_uri;
            }
        }).catch(e=>{console.error("Failed to acquire link",e)})
    }

    const sendToBilling = () => {
        paymentManager.get(window.localStorage.getItem("token"),undefined,'/billing').then(pr=>{
            if(pr.target_url){
                window.location.href=pr.target_url;
            }
        }).catch(e=>{
            console.error("/card/billing error",e);
        })
    };


    const showAppName = useMediaQuery('(min-width:360px)');

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const getScheduler = () =>{
        return (
            <Grid   container
                    direction="column"
                    alignItems="center"
                    justifyContent="center">
                <Grid style={{display:'flex'}} item>
            <TextField
                id="datetime-local"
                label={(selectedDate && !goodDate(selectedDate))? "Must be 5 days ahead and between 9am-6pm": "Book a date at least 5 days ahead"}
                type="datetime-local"
                error={(selectedDate && !goodDate(selectedDate))}
                onChange={(e)=>{
                    if(e?.target?.value){
                        let d = new Date(e?.target?.value);
                        setSelectedDate(d.toISOString());
                    }
                }}
                style={{width:'60vw',marginTop:25}}
                // defaultValue={getDefaultDate()}
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
            />
                    <Button
                        style={{paddingTop:40,marginLeft:30}}
                        component="label"
                        disabled={!selectedDate || !goodDate(selectedDate)}
                        endIcon={<ChevronRightIcon />}
                        onClick={handleNext}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        )
    }

    const showServiceProviderList = () =>{
        return (
            <List subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    Select your preferred provider
                </ListSubheader>
            }>
                {(loProvider && loProvider.length === 0) &&
                <Typography>Sorry, no providers found</Typography>
                }
            {loProvider.map((i)=>(
                <ListItem style={{color:'darkblue'}} button onClick={()=>{setSelectedProvider(i);createAppt(i)}}>
                    <ListItemText primary={i.service + ' - $' + ((i.quote_per_sqft * propertySqft)).toFixed(2) + ' by ' + i.business_name} />
                </ListItem>
            ))}
            </List>)
    }

    const getServiceCreator = (label, inputLabel, mustFind) =>{
        return <Grid container>
            <Grid item>
                <TextField value={businessName} onChange={(e)=>{
                    setBusinessName(e?.target?.value);
                }} className={classes.businessName} variant="outlined" label="" placeholder={inputLabel} />
            </Grid>
            <Grid item>
                <SearchEvents checkOtherInput otherInput={businessName} no_title must_find={mustFind} label={label}  max_events_per_device={1} editMode={false} ready={true}
                              eventSelected={selectedServiceName}  handleBack={handleBack} handleNext={handleNext}
                              activeStep={activeStep} onEventSelected={(ev)=>{ev?.length && setSelectedServiceName(ev)}} />
            </Grid>
        </Grid>;
    }
    const getServiceFinder = (label, inputLabel, mustFind) =>{
        return <Fragment>
            <SearchEvents must_find={mustFind} label={label}  max_events_per_device={1} editMode={false} ready={true}
                          eventSelected={selectedServiceName}  handleBack={handleBack} handleNext={handleNext}
                          activeStep={activeStep} onEventSelected={(ev)=>{ev?.length && setSelectedServiceName(ev)}} />
        </Fragment>;
    }

    const goodPropertySqft = (v) =>{
        return v && v > 300;
    }

    const goodQuoteSqft = (v) =>{
        return v && (v > 0.1) && (v < 25.0);
    }

    const getQuoteField = (isProvider) =>{
        return (
            <Grid   container
                    direction="column"
                    alignItems="center"
                    justifyContent="center">
                <Grid style={{display:'flex'}} item>
                    {isProvider?
                        <TextField style={{marginTop:25}} value={quotePerSqft} error={quotePerSqft && !goodQuoteSqft(quotePerSqft)}  onChange={(v)=>{setQuotePerSqft(parseFloat(v?.target?.value))}} id="outlined-basic" label={(quotePerSqft && !goodQuoteSqft(quotePerSqft)) ? "Must be between 0.1 to 25.0" : "Enter your quote per square foot"} type="number" variant="outlined" InputProps={{
                            startAdornment:(<InputAdornment position="start">$</InputAdornment>),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton disabled={!goodQuoteSqft(quotePerSqft)} onClick={createService} edge="end" color="primary"><ChevronRightIcon /></IconButton>
                                </InputAdornment>
                            ),
                        }} />
                        :
                        <TextField style={{marginTop:25}} value={propertySqft} error={propertySqft && !goodPropertySqft(propertySqft)}   onChange={(v)=>{setPropertySqft(parseFloat(v?.target?.value))}} id="outlined-basic" label={(propertySqft && !goodPropertySqft(propertySqft)) ? "Must be greater than 300":"Enter the property square foot"} type="number" variant="outlined" InputProps={{
                            startAdornment:(<InputAdornment position="start">sqft</InputAdornment>),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton disabled={!goodPropertySqft(propertySqft)} onClick={handleNext} edge="end" color="primary"><ChevronRightIcon /></IconButton>
                                </InputAdornment>
                            ),
                        }} />
                    }
                </Grid>
            </Grid>
        )
    }

    const queryProvider = async (address, placeId)=>{
        setAddress({address, placeId})
        handleNext()
        serviceManager.get(window.localStorage.getItem("token"),undefined,`/search?q=${selectedServiceName}`).then(r=>{
            setLoProvider(r.lo_provider);
        });
    }


    const createAppt = (sp) =>{
        handleNext();
        appointmentManager.post(window.localStorage.getItem('token'), {
            service_name:selectedServiceName,
            provider_id:sp.user_id,
            customer_address:address,
            datetime:selectedDate,
            sqft: propertySqft
        },'/').then((r)=>{
            if(r.status_code === 200 || r.status_code === 201){
                setMessage('Appointment created');
                navigate('/appointments')
            }
            else{
                setMessage("Failed to create Appointment, please try again")
            }
        }).catch(e=>{
            if(e?.response?.data?.message){
                setMessage(e?.response?.data?.message)
            }else{
                setMessage("Failed to create Appointment, please try again")
            }
        })
    }

    const createService = () =>{
        handleNext()
        serviceManager.post(window.localStorage.getItem('token'), {
            service_name:selectedServiceName,
            quote_per_sqft:quotePerSqft,
            business_address:address,
            currency:currency,
            business_name:businessName,
        },'/').then((r)=>{
            if(r.status_code === 200){
                setMessage('Service created')
            }
            else{
                setMessage("Failed to create service, please try again")
            }
        }).catch(e=>{
            if(e?.response?.data?.message){
                setMessage(e?.response?.data?.message)
            }else{
                setMessage("Failed to create service, please try again")
            }
        })
    }

    function getProviderStepContent(step) {
        switch (step) {
            case 0:
                return getServiceCreator("Enter the name of your service","Enter your business name", false)
            case 1:
                return (<Fragment><LocationSearchInput onButtonClick={(address, placeId)=>{setAddress({address, placeId}); handleNext()}} label={'Find your business address'}/></Fragment>)
            case 2:
                return getQuoteField(true)
            case 3:
                return (
                    connectedAccount?
                    <Typography>{(!!message)? message : 'Loading...'}</Typography>
                            :
                    <Button onClick={acquireLink}>Add your account to get paid and accept appointments</Button>
                    )
            default:
                throw new Error('Unknown step');
        }
    }


    function getStepContent(step) {
        switch (step) {
            case 0:
                return getServiceFinder("Find the service you need","Find the service you need",true)
            case 1:
                return (<Fragment><LocationSearchInput onButtonClick={queryProvider} label={'Enter the address to be serviced'}/></Fragment>)
            case 2:
                return getQuoteField()
            case 3:
                return (<Scheduler selectedDate={selectedDate} setSelectedDate={setSelectedDate} handleNext={handleNext}/>)
            case 4:
                return (showServiceProviderList())
            case 5:
                return (customerMember?
                    <Typography>{(!!message)? message : 'Loading...'}</Typography>
                    :
                    <Button onClick={handleNext}>Become a member to receive a 25% discount on all services</Button>)
            default:
                throw new Error('Unknown step');
        }
    }


    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    {displayAppLogo &&
                    <React.Fragment>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            href='/'
                            className={classes.imgIcon}
                        >
                            {/*<img className={classes.imgIcon} src="/favicon.png" width={40} height={40}*/}
                            {/*     alt="Notifycam logo"/>*/}
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            {showAppName &&
                            <a href='/' style={{textDecoration: 'none', color: 'inherit'}}>
                                {CONSTANTS.APP_NAME}
                            </a>
                            }
                        </Typography>
                    </React.Fragment>
                    }
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}>
                <div className={classes.toolbarIcon}>
                    <Typography>
                        Welcome back!
                    </Typography>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>{(userType === UserType.Account) ? mainListItemsWebsite : mainListItems}</List>
                <Divider />
                <List><SecondaryListItems/></List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={1} direction="column" style={{padding:5}}>
                        {typeof userType === 'undefined' &&
                        <Grid item xs={12} md={4} lg={3}>
                            <Typography>Loading data...</Typography>
                            <CircularProgress size={64} />
                        </Grid>
                        }
                        {((userType === UserType.Unknown) && CONSTANTS.FRONT_END_LOGOUT_DEV_MODE) &&
                        <Button style={{backgroundColor:'yellow'}} onClick={()=>{setUserType(UserType.Customer)}}>View as customer</Button>
                        }
                        {((userType === UserType.Unknown) && CONSTANTS.FRONT_END_LOGOUT_DEV_MODE) &&
                        <Button style={{backgroundColor:'blue'}} onClick={()=>{setUserType(UserType.Account)}}>View as provider</Button>
                        }
                        {/*{((userType === UserType.Unknown) && !customerMember) &&*/}
                        {/*<Button style={{backgroundColor:'yellow'}} onClick={sendToBilling}>Become a TaskFast member to get 25% off on all services</Button>*/}
                        {/*}*/}
                        {((userType === UserType.Customer) && !!customerMember) &&
                        <Typography>Enjoy 25% off services as a TaskFast member</Typography>
                        }
                        {/*{((userType === UserType.Unknown) && !customerMember) &&*/}
                        {/*<Button style={{backgroundColor:'blue'}} onClick={acquireLink}>Get started as a Service provider today!</Button>*/}
                        {/*}*/}
                        {((userType === UserType.Account) && !!connectedAccount) &&
                        <Typography>Your account is linked. Start earning today!</Typography>
                        }

                        {message &&
                        <Typography style={{fontSize:'1.3em', color:'red'}}>{message}</Typography>
                        }


                        <Stepper activeStep={activeStep} className={classes.stepper}>
                            {
                                selectedSteps.map((label, index) => {
                                    const labelProps = {};
                                    return (
                                        <Step key={label}>
                                            <StepLabel  {...labelProps}
                                                        StepIconProps={{classes: {completed: classes.completed}}}>{label}</StepLabel>
                                        </Step>
                                    )
                                })
                            }
                            <br/>
                        </Stepper>
                        {(activeStep>0) &&
                        <Button style={{maxWidth: 170, backgroundColor:'white'}} variant={'contained'} onClick={handleBack}>{"< previous step"}</Button>
                        }


                        {((userType === UserType.Account)) &&
                        getProviderStepContent(activeStep)
                        }
                        {((userType === UserType.Customer)) &&
                        getStepContent(activeStep)
                        }
                    </Grid>
                    <Box pt={4}>
                        <Copyright />
                    </Box>
                </Container>
            </main>
        </div>
    );
}



import React, {Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import ExpandMore from '@material-ui/icons/ExpandMore';
import LogoutModal from './LogoutModal';
import LockIcon from '@material-ui/icons/Lock';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import HelpIcon from '@material-ui/icons/Help';
import StorageIcon from '@material-ui/icons/Storage';
import PolicyIcon from '@material-ui/icons/Policy';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EmailIcon from '@material-ui/icons/Email';
import * as CONSTANTS from "../../config/CONSTANTS";

import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import UpdateCard from "./UpdateCard";
import DeleteModal from "./DeleteModal";
import CheckIcon from "@material-ui/icons/Check";
import ChangePasswordModal from "./ChangePasswordModal";
import {LANDING_PAGE_URL} from "../../config/CONSTANTS";
import {paymentManager, userManager} from "../../util/apiRequest";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));
const UserType = {Account:"Account", Customer:"Customer"}

export default function ListOptions(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openContact,setOpenContact]= React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(props.failedDeletion);
    const [token, setToken] = React.useState(window.localStorage.getItem("token"));


    let defPage = -1;
    let defLogout = false;
    let defChangePass = false;

    if(props.jumpToPlan === 1 || props.jumpToPlan === "1"){
        defPage = 0;
    }
    else if(props.jumpToLogout === 1 || props.jumpToLogout === "1"){
        defLogout = true;
    }
    else if(props.jumpToChangePassword === 1 || props.jumpToChangePassword === "1"){
        defChangePass = true;
    }
    const [openLogout, setOpenLogout] = React.useState(defLogout);
    const [openChangePassword, setOpenChangePassword] = React.useState(defChangePass);

    const [currRender, setCurrRender] = React.useState(defPage);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleClickContact = () => {
        setOpenContact(!openContact);
    };

    const renderSettingsHead=(idx) =>{
        switch (idx) {
            case -1:
                if(props.trialActive){
                    return (
                        <Grid container align = "center" justifyContent="center" alignItems="center">
                            <Typography component="h1" variant="h6" align="center" color="textPrimary" gutterBottom>
                                Your free trial is active
                                <CheckIcon  style={{color:'green', fontSize:'100%'}}/>
                            </Typography>
                        </Grid>
                    );
                }
                return null;
            case 0:
                return (
                    <Grid>
                        <Button onClick={()=>{setCurrRender(-1)}} style={{color:'darkblue'}} button component={'a'}>
                            <ArrowBackIcon style={{color:'darkblue'}}  />
                            Back to settings
                        </Button>
                    </Grid>
                )
            case 1:
                return (
                    <Grid>
                        <Button onClick={()=>{setCurrRender(-1)}} style={{color:'darkblue'}} button component={'a'}>
                            <ArrowBackIcon style={{color:'darkblue'}}  />
                            Back to settings
                        </Button>
                    </Grid>
                )

        }
    }

    const renderSetting=(idx) =>{
        switch (idx) {
            case -1:
                return(
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                Settings
                            </ListSubheader>
                        }
                        className={classes.root}
                    >
                        <ListItem style={{color:'darkblue'}} button component={'a'}  href={'/dashboard'}>
                            <ListItemIcon>
                                <ArrowBackIcon style={{color:'darkblue'}}  />
                            </ListItemIcon>
                            <ListItemText primary="Back to dashboard" />
                        </ListItem>
                        {(typeof props.userType !== 'undefined') &&
                        <ListItem onClick={()=>{
                            if(props.userType === UserType.Customer){
                                paymentManager.get(window.localStorage.getItem("token"),undefined,'/billing').then(pr=>{
                                    if(pr.target_url){
                                        window.location.href=pr.target_url;
                                    }
                                }).catch(e=>{
                                    console.error("/card/billing error",e);
                                })
                            } else {
                                // Acquire link
                                paymentManager.get(token,undefined,'/updateAccountLink').then(r=>{
                                    if(r.status_code === 200){
                                        window.open(r.target_url, '_blank').focus();
                                    }
                                }).catch(e=>{
                                    console.error("error",e);
                                })
                            }
                        }} button>
                            <ListItemIcon>
                                <CreditCardIcon />
                            </ListItemIcon>
                            <ListItemText primary={(props.userType === UserType.Customer)? "Billing" : "My account information"} />
                        </ListItem>
                        }


                        <ListItem button onClick={handleClickContact}>
                            <ListItemIcon>
                                <HelpIcon />
                            </ListItemIcon>
                            <ListItemText primary="Have a question? Contact us" />
                            {!openContact ? <ArrowForwardIosIcon style={{color:'gray'}} fontSize={'small'} /> : <ExpandMore style={{color:'gray'}}/>}
                        </ListItem>
                        <Collapse in={openContact} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem style={{color:'darkblue'}}  button className={classes.nested} component={'a'}  href={CONSTANTS.MAIL_TO}>
                                    <ListItemIcon>
                                        <EmailIcon style={{color:'darkblue'}}  />
                                    </ListItemIcon>
                                    <ListItemText primary={CONSTANTS.EMAIL} />

                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem button component={'a'}  href={`${LANDING_PAGE_URL}privacy`}>
                            <ListItemIcon>
                                <StorageIcon />
                            </ListItemIcon>
                            <ListItemText primary="Privacy policy" />
                        </ListItem>
                        <ListItem button component={'a'}  href={`${LANDING_PAGE_URL}terms`}>
                            <ListItemIcon>
                                <PolicyIcon />
                            </ListItemIcon>
                            <ListItemText primary="Terms of use" />
                        </ListItem>
                        {(!props.tpp) &&
                        <ListItem onClick={()=>{setOpenChangePassword(true)}} button>
                            <ListItemIcon>
                                <LockIcon />
                            </ListItemIcon>
                            <ListItemText primary="Change password" />
                        </ListItem>
                        }

                        <ListItem onClick={()=>{setOpenLogout(true)}} button>
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText primary="Log out" />
                        </ListItem>
                        <ListItem button onClick={handleClick}>
                            <ListItemIcon>
                                <MoreHorizIcon />
                            </ListItemIcon>
                            <ListItemText primary="More settings" />
                            {!open ? <ArrowForwardIosIcon style={{color:'gray'}} fontSize={'small'} /> : <ExpandMore style={{color:'gray'}}/>}
                        </ListItem>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem onClick={()=>{setOpenDelete(true)}} button className={classes.nested}>
                                    <ListItemIcon>
                                        <PauseCircleFilledIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Delete account" />
                                </ListItem>
                            </List>
                        </Collapse>
                        <LogoutModal  open={openLogout} handleClose={()=>{setOpenLogout(false);}}/>
                        <DeleteModal tpp={props.tpp} trialActive={props.trialActive} failedDeletion={props.failedDeletion} open={openDelete} handleClose={()=>{setOpenDelete(false);}}/>
                        <ChangePasswordModal  csrfToken={props.csrfToken} open={openChangePassword} handleClose={()=>{setOpenChangePassword(false);}}/>
                    </List>
                )
            case 0:
                return (
                    null
                )
            case 1:
                return (
                    <UpdateCard/>
                )

        }
    }

    return (
        <Fragment>
            {renderSettingsHead(currRender)}
            {renderSetting(currRender)}
        </Fragment>
    );
}

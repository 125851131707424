import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import IndexBar from "../components/IndexBar";
import {rootManager, userManager} from "../util/apiRequest";

const useStyles = makeStyles((theme) => ({
    breakContainer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(3),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(14, 0, 6)
    },
    stepTitle: {
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(2),
    },
    buttonText: {
        [theme.breakpoints.down('sm')]: {
            fontSize:"1.00rem",
        },
        [theme.breakpoints.up('md')]: {
            fontSize:"1.2rem",
        },
        [theme.breakpoints.up('lg')]: {
            fontSize:"1.4rem",
        },
        fontSize:"1.4rem",
        textTransform: "none"
    },
    cardGrid: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(3),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
    card: {
        height: '100%',
        // display: 'flex',
        // flexDirection: 'column',
    },
    cardMain: {
        height: '100%',
        // display: 'flex',
        // flexDirection: 'column',
    },
    cardLink:{
        color: "inherit",
        textDecoration:"none"

    },
    media: {
        maxHeight: "100vh",
        marginTop:-42,
        [theme.breakpoints.down('sm')]: {
            padding:"0px 5px 2px 5px",
            marginTop:-34,
        },
        [theme.breakpoints.down('md')]: {
            padding:"0px 20px 10px 20px",
        },
        [theme.breakpoints.up('lg')]: {
            padding:"0px 40px 20px 40px",
        },
    },
    mediaDemo: {
        maxHeight: "100vh",
        minWidth: "50vw",
        width:300,
        [theme.breakpoints.down('sm')]: {
            padding:"0px 5px 2px 5px",
            marginTop:-34,
        },
        [theme.breakpoints.down('md')]: {
            padding:"0px 20px 10px 20px",
        },
        [theme.breakpoints.up('lg')]: {
            padding:"0px 40px 20px 40px",
        },
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardTitle:{
        paddingTop: '56.25%', // 16:9
       // marginTop: '0', // 16:9

        paddingLeft:'0',
        marginLeft:'0'

    },
    cardContent: {
        // flexGrow: 1,
        alignSelf: "flex-end",
        marginTop:'auto',
        marginBottom:0
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    imgIcon:{
        marginRight:6,
        paddingBottom:12,
        verticalAlign:'middle',
        [theme.breakpoints.down('sm')]: {
            width:80,
            height:80,
        },
        [theme.breakpoints.up('md')]: {
            width:128,
            height:128,

        },
        [theme.breakpoints.up('lg')]: {
            width:180,
            height:180,

        },
    },
    customHeader:{
        [theme.breakpoints.down('sm')]: {
            fontSize:"2.5rem",
        },
        [theme.breakpoints.up('md')]: {
            fontSize:"4.0rem",
        },
        [theme.breakpoints.up('lg')]: {
            fontSize:"6.0rem",
        },
        // fontSize:"5.0rem",
        fontWeight:"700",
        marginBottom:0
    },
    customBodyBelow: {
        [theme.breakpoints.down('sm')]: {
            fontSize: "1.25rem",
        },
    },
    customHeaderBelow:{//Get notified when events happen
        [theme.breakpoints.down('sm')]: {
            fontSize:"1.13rem",
        },
        [theme.breakpoints.up('md')]: {
            fontSize:"1.7rem",
        },
        [theme.breakpoints.up('lg')]: {
            fontSize:"2.1rem",
        },
        // fontSize:"5.0rem",
        fontWeight:"400"
    },
    hideLink:{
        textDecoration:'none',
        color:'inherit'
    },
    rightBar:{
        position:'absolute',
        left:'90%',
    },
    signUp:{
        fontWeight:'normal',
        fontSize:'18px'
    },
    title: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: "2%",
    },
    menuButtonHidden: {
        display: 'none',
    },
}));

function Index(props) {
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState()
    return (
        <React.Fragment>
            <CssBaseline />
            <IndexBar firstName={props.firstName}  dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
            <main>
                <div className={classes.heroContent}>
                    <Container maxWidth="lg">
                        <div className={classes.heroButtons}>
                            <Grid container spacing={2} justifyContent="center">
                            </Grid>
                        </div>
                    </Container>
                </div>
            </main>
        </React.Fragment>
    );
}

export default Index;

/* eslint-disable no-use-before-define */
import React, {useEffect, useState} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {serviceManager} from "../util/apiRequest";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 450,
        maxWidth:'60vw',
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
        color: 'inherit',
    }
}));

function addDefaultCompletion(obj){
    if(!obj?.lo_provider?.length){
        obj.lo_provider = ['Pressure washing'];
    }
}

export default function ObjectComplete(props) {
    const classes = useStyles();
    const [topEvent, setTopEvent] = useState([])
    const [loading, setLoading] = useState(true);
    const [readyToRender, setReadyToRender] = useState(false);

    const [val, setVal] = useState(undefined);

    const [defaultValue, setDefaultValue] = useState();

    async function getTopEvents(){
        try{
            let topE;
            if(props.hardcode){
                topE = {lo_provider:['Pressure washing']}
            } else {
                topE = await serviceManager.get(window.localStorage.getItem("token"),undefined,`/complete`);
            }
            addDefaultCompletion(topE);
            setTopEvent(topE.lo_provider);
            setLoading(false);
        }
        catch (e) {
            console.error(e);
            setTopEvent([]);
            setLoading(false);
        }

    }

    useEffect(() => {
        try{
            setLoading(true)
            if(val){
                if(val.length >0){
                    (async () => {
                        let topE;
                        if(props.hardcode){
                            topE = {lo_provider:['Pressure washing']}
                        } else {
                            topE = await serviceManager.get(window.localStorage.getItem("token"),undefined,`/complete?q=${val}`);
                        }
                        addDefaultCompletion(topE);
                        setTopEvent(topE.lo_provider);
                        setLoading(false);
                    })();
                }
            }
            else{
                (async () => {
                    let topE;
                    if(props.hardcode){
                        topE = {lo_provider:['Pressure washing']}
                    } else {
                        topE = await serviceManager.get(window.localStorage.getItem("token"),undefined,`/complete?q=${val}`);
                    }
                    addDefaultCompletion(topE);
                    setTopEvent(topE.lo_provider);
                    setLoading(false)
                })();
            }
        }catch (e) {
            console.error(e);
            setLoading(false)
        }
    }, [val]);

    useEffect(() => {
        setLoading(true);
        if(props.eventSelected && props.eventSelected.length){
            setDefaultValue(props.eventSelected);
            setReadyToRender(true)
        }
        else if(props.defaultValue){
            setDefaultValue(props.defaultValue);
            setReadyToRender(true)
        }
        else{
            if(props.ready){
                setReadyToRender(true)
            }
        }
        if(props.ready) {
            getTopEvents().catch(e => console.error("Fail to query autocomplete",e))
        }
    },[props.ready]);

    return (
        <div className={classes.root}>
            {(readyToRender && Array.isArray(topEvent)) &&
            <Autocomplete
                noOptionsText={props.label || "Try another keyword"}
                onInputChange={(e)=>{
                    if(e?.target?.value){
                        setVal(e.target.value);
                    }
                    else if(e?.target?.value === 0){
                        setVal(undefined);
                    }
                    if(!props.must_find){
                        if(e?.target?.value){
                            props.onEventSelected(e?.target?.value);
                        }
                    }
                }}
                onChange={(event,value)=>{
                    props.onEventSelected(value);
                }}
                // multiple
                freeSolo={!props.must_find}
                id="multiple-limit-tags"
                loading={loading}
                getOptionSelected={(option, value) => {
                    return option === value
                }}
                options={topEvent}
                getOptionLabel={(option) => option || ""}
                defaultValue={defaultValue? defaultValue : undefined}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="" placeholder={props.label || "Find service..."} />
                )}
            />
            }

        </div>
    );
}

import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {AUTH_API_URI_LOGOUT} from "../../config/CONSTANTS";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor:'none',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),

    },
}));

export default function LogoutModal(props) {
    const classes = useStyles();
    const [seconds, setSeconds] = useState(0);

    const TIME_TIL_REDIRECT = 30;// seconds

    useEffect(() => {
        if(props.open){
            setSeconds(0)
            const timer = setTimeout(() => {
                window.location.href='/'
            }, TIME_TIL_REDIRECT*1000);
            return () => clearTimeout(timer);
        }
    }, [props.open]);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props.open}
                onClose={()=>{props.handleClose()}}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 100,
                }}
            >
                <Fade in={props.open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">
                            Are you sure you want to log out?
                        </h2>
                        <p>You will be automatically redirected in {Math.max(TIME_TIL_REDIRECT - seconds, 0)} seconds</p>
                        <Grid container>
                            <Grid item>
                                <Button onClick={()=>{window.localStorage.removeItem('token'); window.location.href=AUTH_API_URI_LOGOUT;}} component={'a'} >
                                    Logout
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button color={'primary'} variant={'outlined'} onClick={()=> {
                                    props.handleClose()
                                }}>Cancel</Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';


const isObject = val => {
    return typeof val === 'object' && val !== null;
};

const classnames = (...args) => {
    const classes = [];
    args.forEach(arg => {
        if (typeof arg === 'string') {
            classes.push(arg);
        } else if (isObject(arg)) {
            Object.keys(arg).forEach(key => {
                if (arg[key]) {
                    classes.push(key);
                }
            });
        } else {
            throw new Error(
                '`classnames` only accepts string or object as arguments'
            );
        }
    });

    return classes.join(' ');
};

export default class LocationSearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '',
            errorMessage: '',
            selectedAddress:'',
            placeId:''
        };
    }

    handleChange = address => {
        this.setState({
            address,
            errorMessage: '',
        });
    };

    handleSelect = (selected, placeId, suggestion) => {
        this.setState({ address: selected, selectedAddress:selected, placeId:placeId });
    };

    handleCloseClick = () => {
        this.setState({
            address: '',
            latitude: null,
            longitude: null,
        });
    };

    handleError = (status, clearSuggestions) => {
        console.log('Error from Google Maps API', status); // eslint-disable-line no-console
        this.setState({ errorMessage: status }, () => {
            clearSuggestions();
        });
    };

    render() {
        const {
            address,
            errorMessage,
            placeId,
            selectedAddress
        } = this.state;

        return (
            <div>
                <PlacesAutocomplete
                    onChange={this.handleChange}
                    value={address}
                    onSelect={this.handleSelect}
                    onError={this.handleError}
                    shouldFetchSuggestions={address.length > 2}
                    debounce={500}
                    googleCallbackName="initGMaps"
                    searchOptions={{ types: ['address'] }}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps }) => {
                        return (
                            <div className="Demo__search-bar-container">
                                <div className="Demo__search-input-container">
                                    <input
                                        {...getInputProps({
                                            placeholder: this.props.label,
                                            className: 'Demo__search-input',
                                        })}
                                    />
                                    {this.state.selectedAddress.length > 0 && (
                                        <button
                                            className="Demo__clear-button"
                                            onClick={()=>{this.props.onButtonClick(this.state.selectedAddress, this.state.placeId)}}
                                        >
                                            Next
                                        </button>
                                    )}
                                </div>
                                {suggestions.length > 0 && (
                                    <div className="Demo__autocomplete-container">
                                        {suggestions.map(suggestion => {
                                            const className = classnames('Demo__suggestion-item', {
                                                'Demo__suggestion-item--active': suggestion.active,
                                            });

                                            return (
                                                /* eslint-disable react/jsx-key */
                                                <div
                                                    {...getSuggestionItemProps(suggestion, { className })}
                                                >
                                                    <strong>
                                                        {suggestion.formattedSuggestion.mainText}
                                                    </strong>{' '}
                                                    <small>
                                                        {suggestion.formattedSuggestion.secondaryText}
                                                    </small>
                                                </div>
                                            );
                                            /* eslint-enable react/jsx-key */
                                        })}
                                    </div>
                                )}
                            </div>
                        );
                    }}
                </PlacesAutocomplete>
                {errorMessage.length > 0 && (
                    <div className="Demo__error-message">{this.state.errorMessage}</div>
                )}

            </div>
        );
    }
}

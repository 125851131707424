import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {goodDate} from "../util/bookingUtil";
import Button from "@material-ui/core/Button";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

export default function Scheduler(props) {
    const classes = useStyles();

    return (
        <Grid   container
                direction="column"
                alignItems="center"
                justifyContent="center">
            <Grid style={{display:'flex'}} item>
                <TextField
                    id="datetime-local"
                    label={(props.selectedDate && !goodDate(props.selectedDate))? "Must be 5 days ahead and between 9am-6pm": "Book a date at least 5 days ahead"}
                    type="datetime-local"
                    error={(props.selectedDate && !goodDate(props.selectedDate))}
                    onChange={(e)=>{
                        if(e?.target?.value){
                            let d = new Date(e?.target?.value);
                            props.setSelectedDate(d.toISOString());
                        }
                    }}
                    style={{width:'60vw',marginTop:25}}
                    // defaultValue={getDefaultDate()}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                {props.loading ?
                    <CircularProgress />
                    :
                    <Button
                        style={{paddingTop:40,marginLeft:30}}
                        component="label"
                        disabled={!props.selectedDate || !goodDate(props.selectedDate)}
                        endIcon={<ChevronRightIcon />}
                        onClick={props.handleNext}>
                        Next
                    </Button>
                }
                
            </Grid>
        </Grid>
    )

}
